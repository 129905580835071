import React, { useState, useEffect } from 'react';


const Sandbox = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const duration = 60 * 1000; // 60 seconds
    const intervalTime = 10; // Update every 10ms for smoother animation

    const updateProgress = () => {
      setProgress((prev) => {
        if (prev >= 100) {
          return 0; // Reset the timer
        }
        return prev + (intervalTime / duration) * 100;
      });
    };

    const interval = setInterval(updateProgress, intervalTime);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-2 bg-gray-200">
      <div
        className="h-full rounded-lg bg-blue-500"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

  export default Sandbox;


 
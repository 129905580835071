import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth"; 
import ProfileSidebar from './ProfileSidebar';
import { useAuth } from './AuthContext'; 
import {generateUsername} from 'trendy-username';

const Register = ({ onGameChange }) => {
  const { country, fwinBackendUrl } = useAuth();
  const [isLogin, setIsLogin] = useState(true); 
  const [isDeposit, setIsDeposit] = useState(true); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const [uuid, setUuid] = useState("");
  

  const handleGameChange = () => {
    onGameChange("homepage");
  };
  // firebase auth
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (user) {
        const uid = user.uid;
        setUuid(uid)
    }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setSuccess('Logged in successfully!');
      setError('');
    } catch (error) {
      setError(error.message);
      setSuccess('');
    }
  };

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Create user with Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const createdUser = userCredential.user;
      

      // Update uuid directly from the created user
      const userId = createdUser.uid;
      
      
      // Ensure uuid is available
      if (!userId) {
        throw new Error('User ID is not available');
      }

      // Create user in backend and notify external API
      await createUserApi(email, userId, country);

      setSuccess('Registered successfully!');
      setError('');
    } catch (error) {
      setError(error.message);
      setSuccess('');
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
  };
  // Function to create user
  const createUserApi = async (email, userId, country ) => {
    try { 
      // Step 1: Create the user
      const createUserResponse = await fetch(`${fwinBackendUrl}/payments/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, userId, country }),
      });
  
      if (!createUserResponse.ok) {
        throw new Error(`Failed to create user: ${createUserResponse.statusText}`);
      }
  
      console.log('User created');
  
      // Step 2: Notify the external API 
      const notifyResponse = await fetch(`${fwinBackendUrl}/api/new-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (!notifyResponse.ok) {
        throw new Error(`Failed to notify external API: ${notifyResponse.statusText}`);
      }
      console.log('New user notified to external api');

    } catch (error) {
      console.error('Error during user creation or external API notification:', error.message);
      throw error;
    }
  };
  
  



  if (user) {
    return <Profile  handleLogout={handleLogout} isDeposit={isDeposit} setIsDeposit={setIsDeposit} />;
  }

  return (
    <div className="w-full h-screen">
      <div className='flex justify-end items-center bg-gray-800 w-full'>
        <div onClick={handleGameChange}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
      <div className=" px-2 py-8">
        <div className="flex space-x-4 border-b border-slate-200">
          <div
            className={`flex-1 flex items-center justify-center cursor-pointer ${isLogin ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
            onClick={() => setIsLogin(true)}
          >
            <span className='font-bold'>Login</span>
          </div>
          <div
            className={`flex-1 flex items-center justify-center cursor-pointer ${!isLogin ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
            onClick={() => setIsLogin(false)}
          >
            <span className='font-bold'>Join</span>
          </div>
        </div>
        <div className='form-container'>
          {isLogin ? (
            <LoginForm
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              handleLogin={handleLogin}
              error={error}
              success={success}
            />
          ) : (
            <RegisterForm
              email={email}
              password={password}
              confirmPassword={confirmPassword}
              setEmail={setEmail}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              handleRegister={handleRegister}
              error={error}
              success={success}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const Profile = () => {

  

  return (
    <>
    <ProfileSidebar  />
    </>
  );
};

function LoginForm({ email, password, setEmail, setPassword, handleLogin, error, success }) {
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your password"
          />
        </div>
        <button
          type="button"
          onClick={handleLogin}
          className="w-full bg-[#3230C4] text-white p-2 rounded hover:bg-blue-600"
        >
          Login
        </button>
      </form>
    </div>
  );
}

function RegisterForm({ email, password, confirmPassword, setEmail, setPassword, setConfirmPassword, handleRegister, error, success }) {
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Register</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter your password"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="confirm-password">Confirm Password:</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Confirm your password"
          />
        </div>
        <button
          type="button"
          onClick={handleRegister}
          className="w-full bg-[#3230C4] text-white p-2 rounded hover:bg-green-600"
        >
          Register
        </button>
      </form>
    </div>
  );
}

export default Register;
